<script setup>
import { onMounted, ref } from 'vue'
import { list, edit } from '@/api/timingSend.js'
import { message } from "ant-design-vue";

const PAGE_SIZE = 20

const btnLabel = {
  0: '上架', 1: '下架'
}
const stateLabel = {
  0: '已下架', 1: '已上架'
}
const typeArr = [
  { label: '纯文字', value: '0' },
  { label: '纯图片', value: '1' },
  { label: '文字图片', value: '2' },
]
const pageNum = ref(1)
const pageSize = ref(PAGE_SIZE)

const formRef = ref()
const columns = [
  { title: "ID", dataIndex: "logicId", width: "15%" },
  { title: "时间", dataIndex: "time", width: "50%" },
  {
    title: "状态",
    dataIndex: "status",
    width: "15%",
    slots: { customRender: "status" }
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: "20%",
    slots: { customRender: "operation" }
  }
]
const source = ref([])
const loading = ref(false)
const pagination = ref({
  position: "bottom",
  total: 0, // 条数
  defaultPageSize: PAGE_SIZE, // 默认分页条数
  pageSizeOptions: ["5", "10", "15", "20", "30"],
  showSizeChanger: true, // 是否可以改变 pageSize
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `共 ${total} 条`,
  hideOnSinglePage: false, // 只有一页时是否隐藏分页器
  onChange: (selectedRowKeys, selectedRows) => {
    loading.value = true;
    getList({ pageNum: selectedRowKeys, pageSize: selectedRows });
  }
})

const addActive = () => {
  modalTitle.value = '新增定时群发'
  formModal.value.data = {
    time: null,
    type: '0',
    text: '',
    image: '',
    status: '0'
  }
  modalVisible.value = true
}
const editActive = (obj) => {
  modalTitle.value = '修改定时群发'
  const { time, type, text, image, logicId, status } = obj
  formModal.value.data = {
    time, type: type.toString(), text, image, logicId, status
  }
  modalVisible.value = true
}
const changeStatus = async (obj) => {
  const { time, type, text, image, logicId, status } = obj
  const { status: resStatus, msg } = await edit({ time, type, text, image, logicId, status: status == '0' ? '1' : '0' })
  resStatus == '200' ? message.success(msg) : message.warning(msg)
  refresh()
}
const getList = async (params) => {
  pageNum.value = params.pageNum
  pageSize.value = params.pageSize
  const { data } = await list(params)
  const { records, total } = data
  source.value = records
  pagination.value.total = total
  loading.value = false
}
const refresh = () => getList({ pageNum: pageNum.value, pageSize: pageSize.value })

onMounted(refresh)

const modalTitle = ref('新增定时群发')
const modalVisible = ref(false)
const modalLoading = ref(false)
const formModal = ref({
  data: {
    time: null,
    type: '0',
    text: '',
    image: '',
    status: '0'
  },
  rules: {
    text: [{ required: true, message: '必填项', trigger: 'blur' }]
  }
})
const handleChange = (val) => {
  switch (val) {
    case '0':
      formModal.value.rules = { text: [{ required: true, message: '必填项', trigger: 'blur' }] }
      break;
    case '1':
      formModal.value.rules = { image: [{ required: true, message: '必填项', trigger: 'blur' }] }
      break;
    case '2':
      formModal.value.rules = { text: [{ required: true, message: '必填项', trigger: 'blur' }], image: [{ required: true, message: '必填项', trigger: 'blur' }] }
      break;
  }
}
const handleOk = async () => {
  formRef.value.validateFields().then(async () => {
    modalLoading.value = true
    const { status, msg } = await edit(formModal.value.data)
    status == '200' ? message.success(msg) : message.warning(msg)
  }).finally(() => {
    handlerCancel()
  })

}
const handlerCancel = () => {
  modalLoading.value = false
  modalVisible.value = false
  formRef.value.resetFields()
  refresh()
}
</script>
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="addActive">新建定时群发</a-button>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="loading">
    <template #status="{ record }">{{ stateLabel[record.status] }}</template>
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="changeStatus(record)">
        {{ btnLabel[record.status] }}
      </a-button>
      <a-button type="primary" size="small" style="margin-left: 10px" @click="editActive(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal :title="modalTitle" v-model:visible="modalVisible" :confirm-loading="modalLoading" @ok="handleOk"
    @cancel="handlerCancel" width="500px">
    <a-form ref="formRef" :model="formModal.data" :rules="formModal.rules" :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }">
      <a-form-item label="群发时间" name="time">
        <a-time-picker v-model:value="formModal.data.time" valueFormat="s m H * * ?" />
      </a-form-item>
      <a-form-item label="群发类型" name="type">
        <a-select v-model:value="formModal.data.type" @change="handleChange">
          <template v-for="item of typeArr" :key="item.value">
            <a-select-option :value="item.value">{{ item.label }}</a-select-option>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item label="文字内容" name="text">
        <a-textarea :rows="3" v-model:value="formModal.data.text" />
      </a-form-item>
      <a-form-item label="图片链接" name="image">
        <a-input v-model:value="formModal.data.image" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
