import { request, request2 } from "../utils/axios";
import { stringify } from "qs";

// 定时群发列表
export function list(params) {
  return request({
    url: `/wuan/admin/wx/groupSend/page?${stringify(params)}`,
    method: "get",
  });
}

// 修改定时群发
export function edit(params) {
  return request2({
    url: `/wx/groupSend/saveOrUpdate?${stringify(params)}`,
    method: "post",
  });
}
